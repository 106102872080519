import { EnvSettings, ProductConfig } from './product-config'
import { SharedConfig, SharedEnvSettings } from './shared-config'
import sharedConfigValue from './shared-config.yml'

const sharedConfig = sharedConfigValue as SharedConfig

const render = (productConfig: ProductConfig) => {
  const getQueryParam = (param) => new URLSearchParams(window.location.search).get(param)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const windowAny = window as any
  windowAny.getQueryParam = getQueryParam

  const {
    location: { hostname },
  } = window

  interface LayoutInjector {
    html: string
    script: string
  }

  const cartUnifiedLayout = (sharedSettings: SharedEnvSettings): LayoutInjector => {
    const { layout } = productConfig

    return {
      html: `
      <cart-layout
        logout-url="${layout.logoutUrl}"
        product="${productConfig.product}"
        routes-with-blank-layout="${layout.routes.blankLayout.join(',')}"
        unauthenticated-routes="${layout.routes.unauthenticated.join(',')}"
        oauth-callback-routes="${layout.routes.oauthCallback.join(',')}">
      </cart-layout>
  `,
      script: `${sharedSettings.packageUrl}/layout/${sharedSettings.versions.layout}/${sharedSettings.bundle}`,
    } as LayoutInjector
  }

  const cartUnifiedSidebar = (sharedSettings: SharedEnvSettings): LayoutInjector => {
    const { navUrls } = sharedSettings

    return {
      html: `
  <main data-cartid="layout-container">
    <cart-unified-sidebar
      business-id="${getQueryParam('businessId')}"
      dfw-url="${navUrls.dfw}"
      jazz-url="${navUrls.jazz}"
      mcm-url="${navUrls.mcm}"
      dfw-url="${navUrls.dfw}"
      bc-url="${navUrls.bc}"
      product="${productConfig.product}"
      spa="true"
      uns-url="${navUrls.uns}"
    ></cart-unified-sidebar>
    <div id="cartdotcom-container" data-cartid="app-container"></div>
  </main>
`,
      script: `${sharedSettings.packageUrl}/sidebar/${sharedSettings.versions.sidebar}/${sharedSettings.bundle}`,
    }
  }

  const setLayoutWrapper = (html: string) => {
    const layoutWrapper: Element | null = document.body.querySelector('#layout-wrapper')
    if (!layoutWrapper) {
      throw new Error('Element #layout-wrapper must be present on the page.')
    }

    layoutWrapper.innerHTML = html
  }

  const updateLinks = (event, param) => {
    const { target } = event
    if (target.tagName.toLowerCase() === 'a' || target.closest('a')) {
      event.preventDefault()
      const element = target.tagName === 'A' ? target : target.closest('a')
      const params = new URLSearchParams(window.location.search)
      params.set(param, 'true')
      element.href = `${element.href.split('?')[0]}?${params.toString()}`
    }
  }

  const updateLinksConstellation = (event) => {
    updateLinks(event, 'constellation')
  }

  const checkForConstellation = async () => {
    const loadCartNav = getQueryParam('loadCartNav') !== 'false'
    if (!loadCartNav) {
      const skipHtml = '<div id="cartdotcom-container"><app></app></div>'
      setLayoutWrapper(skipHtml)
      return
    }

    const loadLocalNav = getQueryParam('loadLocalNav') === 'true'
    if (loadLocalNav && document.currentScript?.getAttribute('skipChecks') !== 'true') {
      const localNavScript = document.createElement('script')
      localNavScript.setAttribute('skipChecks', 'true')
      localNavScript.src = `http://localhost:8083/bootstrap/bootstrap-${productConfig.product}.js`
      document.head.appendChild(localNavScript)
      return
    }

    const isConstellation = getQueryParam('constellation') === 'true'

    const environment: EnvSettings | undefined = productConfig.envs.find(
      (e) => e.hostnames.includes(hostname) || e.hostnames.length === 0,
    )

    if (!environment) {
      throw new Error(`Environment for ${hostname} not found.`)
    }

    const isJazz = productConfig.product === 'jazz'

    let injects: LayoutInjector

    const sharedSettings = sharedConfig.envs[environment.name]
    if (isConstellation || isJazz) {
      document.addEventListener('click', updateLinksConstellation)
      injects = cartUnifiedSidebar(sharedSettings)
    } else {
      injects = cartUnifiedLayout(sharedSettings)
    }

    // NOTE: Jazz needs to remove their USB implementation
    // and use bootstrap file instead
    if (!isJazz) {
      setLayoutWrapper(injects.html)
    }

    const layoutScript = document.createElement('script')
    layoutScript.src = injects.script
    layoutScript.type = 'module'
    document.head.appendChild(layoutScript)
  }

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', checkForConstellation)
  } else {
    checkForConstellation()
  }
}

export default render
